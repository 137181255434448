import React, {useEffect, useReducer} from 'react';
import {Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faCheck, faPenToSquare, faPercent, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import API from '../services/API';
import Error from '../services/Error';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/es';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentDiscountCodeId, setCurrentDiscountCodeId] = React.useState(false);
  const [currentDiscountCodeName, setCurrentDiscountCodeName] = React.useState('');
  const [vehicles, setVehicles] = React.useState([]);
  const [discountCodes, setDiscountCodes] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currency, setCurrency] = React.useState({code: 'EUR', symbol: '€'});
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));
  const DATE_FORMAT = 'YYYY/MM/DD';
  const FR_DATE_FORMAT = 'DD/MM/YYYY';

  const getLocaleDateFormat = () => {
    return Utils.getLanguage().toUpperCase() === 'FR' ? FR_DATE_FORMAT : DATE_FORMAT;
  }

  const refreshDiscountCodes = () => {
    API.getDiscountCodes(history).then(response => {
      if (response && response.data.success === true) {
        setDiscountCodes(response.data.data.discountCodes);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshDiscountCodes();
    API.getCurrency(history).then(response => {
      if (response && response.data.success === true) {
        setFormatter(new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: response.data.data.code,
        }));
        setCurrency(response.data.data);
      }
    });
  }, []);

  const getVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleChangeCode = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value.replace(/\s/g, '')
    });
  }

  const handleChangeStartDate = value => {
    setFormData({
      name: 'startDate',
      value: moment(value).format(DATE_FORMAT)
    });
  }

  const handleChangeEndDate = value => {
    setFormData({
      name: 'endDate',
      value: moment(value).format(DATE_FORMAT)
    });
  }

  const handleAddOrEdit = (e) => {
    e.preventDefault();

    if (vehicles.length === 0) {
      getVehicles();
    }

    if (editModalMode) {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.putDiscountCodes(history, formData, currentDiscountCodeId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshDiscountCodes();
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeInvalidDates()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'DISCOUNT_CODE_INVALID_DATES'}));
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeWithoutDiscount()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'DISCOUNT_CODE_WITHOUT_DISCOUNT'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.postDiscountCodes(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'DISCOUNT_CODE_ADDED'}));
          setShowModal(false);
          refreshDiscountCodes();
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeAlreadyExists()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'DISCOUNT_CODE_ALREADY_EXISTS'}));
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeInvalidDates()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'DISCOUNT_CODE_INVALID_DATES'}));
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeWithoutDiscount()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'DISCOUNT_CODE_WITHOUT_DISCOUNT'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleNewDiscountCodeButton = () => {
    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData({});
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditDiscountCodeButton = (props) => {
    let data = Object.assign({}, props);

    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData(data);
    setCurrentDiscountCodeId(data.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisableDiscountCode(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'DISCOUNT_CODE_DISABLED'}));
          refreshDiscountCodes();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnableDiscountCode(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'DISCOUNT_CODE_ENABLED'}));
          refreshDiscountCodes();
        } else if (response && response.data.success === false && response.data.data === Error.discountCodeCannotBeEnabledWithoutVehicule()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'YOU_MUST_ASSIGN_VEHICLE_BEFORE'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeleteDiscountCodeButton = (props) => {
    setCurrentDiscountCodeId(props.id);
    setCurrentDiscountCodeName(props.code);
    setShowDeleteModal(true);
  }

  const deleteDiscountCode = () => {
    API.deleteDiscountCodes(history, currentDiscountCodeId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'DISCOUNT_CODE_DELETED'}));
        setShowDeleteModal(false);
        refreshDiscountCodes()
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {code, fixedDiscount, percentageDiscount, startDate, endDate, maxUsage, used, vehicleId, vehicleName, active} = props;

    return (
      <tr>
        <td className="td-center">
          {code}
        </td>
        <td className="td-center">
          {formatter.format(fixedDiscount)}
        </td>
        <td className="td-center">
          {percentageDiscount}{percentageDiscount ? ' %' : ''}
        </td>
        <td className="td-center">
          {startDate ? moment(startDate).format(getLocaleDateFormat()) : ''}
        </td>
        <td className="td-center">
          {endDate ? moment(endDate).format(getLocaleDateFormat()) : ''}
        </td>
        <td className="td-center">
          {(used ? used : 0) + ' / ' + (maxUsage ? (maxUsage === 0 ? '~' : maxUsage) : '~')}
        </td>
        <td className="td-center">
          {vehicleId === -1 ? intl.formatMessage({id: 'ALL_VEHICLES'}) : vehicleName}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditDiscountCodeButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                          }>
            <Button variant="danger" onClick={() => handleDeleteDiscountCodeButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewDiscountCodeButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_DISCOUNT_CODE"/></span></Button>
      <br/><br/>
      {(discountCodes && discountCodes.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <Table responsive className="table-centered table-nowrap rounded mb-0">
                  <thead className="thead-light">
                  <tr>
                    <th className="border-0 text-center"><FormattedMessage id="CODE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="FIXED_REDUCTION_AMOUNT" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="DISCOUNT_PERCENTAGE" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="START_DATE" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="END_DATE" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="USAGE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
                  </tr>
                  </thead>
                  <tbody>
                  {discountCodes && discountCodes.map((data, index) => <TableRow key={`discount-${data.id}`} {...data} />)}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_DISCOUNT_CODE"/> : <FormattedMessage id="NEW_DISCOUNT_CODE"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-discount-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Form.Group>
                <Form.Label><FormattedMessage id="CODE"/></Form.Label>
                <Form.Control required type="text" id="code" maxLength="32" disabled={editModalMode}
                              onChange={handleChangeCode} value={formData.code}/>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="FIXED_REDUCTION_AMOUNT" values={{newLine: ' '}}/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text style={editModalMode ? {background: '#f5f8fb'} : {}}>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="fixedDiscount" step="any" min="0" disabled={editModalMode}
                                  onChange={handleChange} value={formData.fixedDiscount}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="DISCOUNT_PERCENTAGE" values={{newLine: ' '}}/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text style={editModalMode ? {background: '#f5f8fb'} : {}}>
                      <FontAwesomeIcon icon={faPercent}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="percentageDiscount" step="any" min="0" disabled={editModalMode}
                                  onChange={handleChange} value={formData.percentageDiscount}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="START_DATE" values={{newLine: ' '}}/></Form.Label>
                  <Datetime
                    locale={Utils.getLanguage()}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleChangeStartDate}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt}/>
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={formData.startDate ? moment(formData.startDate).format(getLocaleDateFormat()) : ''}
                          onFocus={openCalendar}/>
                      </InputGroup>
                    )}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="END_DATE" values={{newLine: ' '}}/></Form.Label>
                  <Datetime
                    locale={Utils.getLanguage()}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleChangeEndDate}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt}/>
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={formData.endDate ? moment(formData.endDate).format(getLocaleDateFormat()) : ''}
                          onFocus={openCalendar}/>
                      </InputGroup>
                    )}/>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="MAXIMUM_USAGE"/></Form.Label>
                  <OverlayTrigger trigger={focus}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_MAXIMUM_USAGE"/></Tooltip>
                                  }>
                    <Form.Control type="number" id="maxUsage" step="any" min="0"
                                  onChange={handleChange} value={formData.maxUsage}/>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="VEHICLE"/></Form.Label>
                  <Form.Select id="vehicleId" onChange={handleChange} value={formData.vehicleId}>
                    <option key={`vehicle-all`} value={-1}>{intl.formatMessage({id: 'ALL_VEHICLES'})}</option>
                    {vehicles.map(v => <option key={`vehicle-${v.id}`} value={v.id}>{v.name}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-discount-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_DISCOUNT_CODE" values={{code: currentDiscountCodeName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteDiscountCode}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
    ;
};
